import React from "react";
import { useEffect } from "react";
import { navigate } from "gatsby";

import { storage } from "src/configs/cookieStorage";
import SEO from "src/components/SEO";

const Index = () => {
  useEffect(() => {
    const language = storage().language();
    navigate(`/${language}`);
  }, []);
  return (
    <React.Fragment>
      <SEO
        description={`Henesis is the most powerful and secure crypto wallet API. Provide great user experience and secure digital assets with Henesis. You can integrate it within two days.`}
      />
    </React.Fragment>
  );
};

export default Index;
